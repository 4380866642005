<template>
    <div class="headerBox">
        <div class="User">
            <div class="left">
                {{ $route.meta.title }}
            </div>
            <div class="right">
                <div class="t" >{{ $utils.storage.get("shInfo") ? $utils.storage.get("shInfo").name : '' }}，欢迎您！</div>
                <el-tooltip class="item" effect="dark" content="消息列表" placement="bottom-start">
                <div class="news" @click="message">
                    <i class="el-icon-bell"></i>
                    <span class="newsNumber" v-if="newsNumber > 0">{{ newsNumber }}</span>
                </div>
            </el-tooltip>

            <div @click="changePassword">
                <el-tooltip class="item" effect="dark" placement="bottom-start">
                    <div slot="content" style="text-align: center;">{{ $utils.storage.get("shInfo") ? $utils.storage.get("shInfo").name : '' }}</div>
                    <div slot="content" style="text-align: center">{{ $utils.storage.get("mobileNumber") }}</div>
                    <el-avatar
                        icon="el-icon-user-solid"
                        :size="32"
                        :src="$utils.storage.get('userBasicInfo') ? $utils.storage.get('userBasicInfo').avatar : ''"
                        fit="cover"
                    ></el-avatar>
                </el-tooltip>
            </div>
            <el-tooltip class="item" effect="dark" content="退出登录" placement="bottom-start">
                <i class="el-icon-switch-button quit" @click="LoginOut()"></i>
            </el-tooltip>
            </div>
           
        </div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
            <el-breadcrumb-item v-for="(m, mi) in breadcrumbList" :key="mi" :to="{ path: m.path }">{{
                m.meta.title
            }}</el-breadcrumb-item>
        </el-breadcrumb> -->
        <transition name="slide-up">
            <el-card class="box-card message" v-if="showMessage">
                <div slot="header" class="clearfix">
                    <span class="header_tit"><i class="el-icon-info"></i><span class="tit">消息提示</span></span>
                    <i class="el-icon-close" @click="showMessage = false"></i>
                </div>
                <div @click="goMessage">
                    你有新订单消息 <span class="chakan">点击查看<i class="el-icon-arrow-right"></i></span>
                </div>
            </el-card>
        </transition>
    </div>
</template>

<script>
import userApi from "../../api/userApi";
import paymentOrderApi from "../../api/paymentOrderApi";

export default {
    name: "User",

    data() {
        return {
            avatar: "",
            username: "",
            newsNumber: null,
            dataList: [],
            showMessage: false,

            breadcrumbList: [],
        };
    },
    mounted() {
        this.getStages();
        this.breadcrumbList = this.$route.matched;
        

        let timeList = ["11:01:00", "14:01:00", "16:01:00"];
        timeList.forEach(item => {
            let nowtime = new Date().getTime();
            let onetime = new Date(this.getCurrentDate(item)).getTime();
            if (nowtime > onetime) {
                return;
            } else {
                let t = onetime - nowtime;
                setTimeout(() => {
                    this.getStages();
                }, t);
            }
        });
    },
    methods: {
        

        UserInfo() {
            userApi.UserInfo({}).then(response => {
                if (response.code == 0) {
                    this.$utils.storage.set("userBasicInfo", response.data.userBasicInfo);
                    this.$utils.storage.set("userGroupList", response.data.userGroupList);
                    this.username = this.$utils.storage.get("userBasicInfo").username;
                }
            });
        },
        LoginOut() {
            this.$router.push({ name: "login" });
            this.$utils.storage.remove("X-Access-Token");
            this.$utils.storage.remove("shInfo");
            this.$utils.storage.remove("mobileNumber");
            this.$utils.storage.remove("userInfo");
            this.$utils.storage.remove("isAdmin");
            this.$utils.storage.remove("admJoinObj");
            location.reload();
        },
        changePassword() {
            // this.$router.push({ name: "changePassword" });
        },

        getStages() {
                paymentOrderApi.paymentRecord().then(res => {
                    if (res.success && res.data.length > 0) {
                        this.showMessage = res.data[0].isRead ? false : true;
                        let newsNumber = 0;
                        res.data.forEach(item => {
                            if (!item.isRead) {
                                newsNumber++;
                            }
                        });
                        newsNumber == 0 ? (newsNumber = null) : newsNumber;
                        this.newsNumber = newsNumber > 99 ? "99+" : newsNumber;
                    }
                });
           
        },
        getCurrentDate(time) {
            let now = new Date();
            let year = now.getFullYear();
            let month = now.getMonth() + 1;
            let day = now.getDate();
            return year + "-" + month + "-" + day + " " + time;
        },
        goMessage() {
            this.showMessage = false;
            this.newsNumber = 0;
            if (this.$route.path !== "/messagePage") {
                this.$router.push({ name: "messagePage" });
            }
            this.$bus.$emit("reload-data");
        },
        message() {
            if (this.$route.path !== "/messagePage") {
                this.$router.push({ name: "messagePage" });
            }
            this.$bus.$emit("reload-data");
        },

        handleVisibilityChange() {
            this.pageVisibility = document.visibilityState;
            if (this.pageVisibility === "visible") {
                this.getStages();
            }
        }
    },
    components: {}
};
</script>

<style lang="scss" scoped>
.headerBox{
    position: relative;
    .breadcrumb{
        position: absolute;
        left: 16px;
        bottom: -18px;
        cursor: pointer;
        font-size: 12px;
    }
}
.User {
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 60px;
    background: #fff;
    justify-content: space-between;
    padding-right: 16px; 
    margin-right: 16px;
    border-radius: 4px;
    .left{
        width: 200px;
        color: #000;
        font-weight: 700;
        padding-left: 20px;
        font-size: 20px;
    }
    .right{
        display: flex;
        align-items: center;

        .t{
            color: #909399;
            font-size: 14px;
        }
        .news {
            margin-right: 20px;
            position: relative;
            .newsNumber {
                position: absolute;
                top: -6px;
                right: -6px;
                background: #f56c6c;
                width: 16px;
                height: 16px;
                display: block;
                border-radius: 50%;
                color: #fff;
                font-size: 10px;
                text-align: center;
                line-height: 16px;
            }
        }
        i {
            font-size: 18px;
            font-weight: 700;
            margin-left: 20px;
            color: #000;
        }
        .quit:hover {
            color: #457ff5;
        }
    }
    
}
.editPassword {
    color: #457ff5;
    margin-bottom: 20px;
    cursor: pointer;
}
.slide-up-enter-active,
.slide-up-leave-active {
    transition: transform 0.3s ease-out;
}
.slide-up-enter-active,
.slide-up-leave-active {
    transition: transform 0.3s ease-out;
}
.slide-up-enter,
.slide-up-leave-to {
    transform: translateY(100%);
}

.message {
    position: fixed;
    bottom: 30px;
    right: 20px;
    transition: transform 0.3s ease-out;
    background: #fff;
    border: 1px solid #cccccc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-weight: 600;
    border-radius: 6px;
    i {
        font-size: 14px;
        font-weight: 600;
    }
    .el-card__body {
        display: flex;
        .chakan {
            color: #409eff;
            i {
                font-size: 12px;
            }
        }
    }

    .clearfix {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header_tit {
            display: flex;
            align-items: flex-end;
            .tit {
                margin-left: 3px;
            }
            i {
                color: #f78989;
                font-weight: 500;
            }
        }
    }
    /deep/.el-card__header {
        padding: 12px 10px;
        background: rgb(241, 245, 249);
    }
}
</style>