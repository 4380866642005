<template>
    <div class="Full">
        <el-header  v-if="!isPc">
            <div class="logo">代扣信息管理系统</div>
            <header-com :menuList="menuList"></header-com>
        </el-header>
        <el-container>
            <!-- <el-aside width="200px" ref="asideBox" id="asideBox" v-if="showMenu">
                <el-menu
                    @select="menuPush"
                    background-color="#333E52"
                    text-color="#fff"
                    active-text-color="#ffd04b"
                    style="border: 0"
                >
                    <template v-for="(menu, index) in menuList">
                        <el-menu-item :index="index.toString()" v-if="!menu.chaildren">
                            <i class="el-icon-menu"></i>
                            <span slot="title">{{ menu.name }}</span>
                        </el-menu-item>
                      
                        <el-submenu index="1" v-if="menu.component == 'userConfigure'">
                            <template slot="title">
                                <i class="el-icon-menu"></i>
                                <span slot="title">{{ menu.name }}</span>
                            </template>
                            <el-menu-item-group v-for="(item, i) in menu.chaildren" :key="i">
                                <el-menu-item :index="'u' + i">{{ item.name }}</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                    </template>
                </el-menu>
            </el-aside> -->


            <newMenu :menuList="menuList" @showMenu="showMenu" v-if="isPc"></newMenu>
           
            <el-main id="mainBox" ref="mainBox">
                <user></user>
                <div class="content-wrap" ref="contentwrap" id="contentwrap">
                    <router-view />
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { HeaderCom, BreadCrumb, newMenu , user} from "./comm";
export default {
    name: "Full",
    data() {
        return {
            menuList: [
            {
                    name: "签约订单",
                    component: "contractOrder",
                    icon:'el-icon-s-order'
                },
                {
                    name: "支付订单",
                    component: "paymentOrder",
                    icon:"el-icon-s-goods"
                },
                {
                    name: "历史订单",
                    component: "historyOrder",
                    icon:'el-icon-date'
                },
                {
                    name: "消息列表",
                    component: "messagePage",
                    icon:'el-icon-message'
                },
                {
                    name: "合同备案",
                    component: "contractBackups",
                    icon:'el-icon-document-copy'
                },
                {
                    name: "线上协议(暂定)",
                    component: "onlineProtocol",
                    icon:'el-icon-link'
                },
                {
                    name: "商户管理",
                    component: "businessConfigure",
                    icon:'el-icon-s-custom',
                    
                }
            ],

            // showMenu: true

            showM:true,

            isPc:false
        };
    },
    created() {},
    watch:{
        showM(n,o){
            if (!n) {
                document.getElementById('mainBox').style.marginLeft='60px'
                document.getElementById('mainBox').style.transition='margin-left 0.5s'
               
            }else{
                document.getElementById('mainBox').style.marginLeft='216px'
                document.getElementById('mainBox').style.transition='margin-left 0.5s'
            }
        }
    },
    mounted() {
        if (this.isMobile()) {
            this.isPc = false;
        } else {
            this.isPc = true;
        }
       
    },
    methods: {
        menuPush(e) {
            if (e.indexOf("u") != -1) {
                this.$router.push({
                    name: this.menuList[5].chaildren[Number(e.split("u")[1])].component
                });
            } else {
                this.$router.push({
                    name: this.menuList[e].component
                });
            }
        },
        
        showMenu(e){
            this.showM=e
            if (!e) {
                document.getElementById('mainBox').style.marginLeft='60px'
                document.getElementById('mainBox').style.transition='margin-left 0.5s'

            }else{
                document.getElementById('mainBox').style.marginLeft='216px'
                document.getElementById('mainBox').style.transition='margin-left 0.5s'
            }
        },

        isMobile() {
            // 判断是否为移动设备
            return (
                typeof window.orientation !== "undefined" || // 判断是否存在window.orientation属性，此属性在移动设备上一般存在
                navigator.userAgent.indexOf("IEMobile") !== -1 || // 判断是否为Windows Phone
                navigator.userAgent.indexOf("iPhone") !== -1 || // 判断是否为iPhone
                (navigator.userAgent.indexOf("Android") !== -1 && navigator.userAgent.indexOf("Mobile") !== -1) || // 判断是否为Android手机
                navigator.userAgent.indexOf("BlackBerry") !== -1 || // 判断是否为BlackBerry
                navigator.userAgent.indexOf("Opera Mini") !== -1 // 判断是否为Opera Mini浏览器
            );
        }
    },
    components: {
        HeaderCom,
        BreadCrumb,
        newMenu,
        user
    }
};
</script>

<style lang="scss" scoped>
.Full {
    .el-header {
        padding: 0 0;
        // background: rgba(0,0,0,0.8);
        background: #404e68;
        position: relative;
        .logo {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            position: absolute;
            top: 30%;
            left: 20px;
        }
    }
    #mainBox{
        margin-left: 60px;
        padding-left: 16px;
    }
    .el-main {
        // background-color: #eaeef5;
        overflow: hidden;
        padding: 0;
    
    }
    .el-aside {
        background: #333e52;
        // min-height: 93vh;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .el-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        flex-basis: auto;
        box-sizing: border-box;
        min-width: 0;
    }

    #contentwrap{
        margin-right: 16px;
    }

    @media (max-width: 600px) {
        #mainBox{
           margin: 0;
           padding: 0 0 0 16px;
        }
        .el-header {
            display: flex;
            justify-content: right;
            align-items: center;
            padding-right: 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            width: 100%;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 999;
        }

        .el-main {
            padding: 0 16px;
            margin-top: 56px;
        }
    }
}
</style>
