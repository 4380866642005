import utilsApi from '../assets/js/utils'

import ajax from '../assets/js/axios.config'

export default utilsApi.creatApi({
    UserInfo: ['get', '/adminUser/get'], // 用户信息
    UserLogin: ['post', '/doLogin'], // 用户登录
    Logout: ['post', '/sales/logout'], // 退出登录
    SendSmsCode: ['post', '/sendVerificationCode'], // 发送验证码
    UserloginTest: ['get', '/loginTest'], // 用户登录

    updateMobileNumber: ['post', '/adminUser/updateMobileNumber'], // 修改手机号
    verifyOldMobileNumber: ['get', '/adminUser/verifyOldMobileNumber'], // 验证手机号

    
    
    getUserInfo: ['get', '/adminUser/get'],

    sendVerifyCode: ['post', '/api/user/sendVerifyCode/LOGIN'], // 发送验证码

    login: ['post', '/api/user/doLogin'], // 用户登录

    getLoginUserInfo: ['get', '/api/user/getLoginUserInfo'],


    info: ['post', '/api/tenant/get'],


    
    
})