import utilsApi from '../assets/js/utils'

import ajax from '../assets/js/axios.config'

export default utilsApi.creatApi({
   // getPageList:['post', '/payOrders/page'], 
   // getOrder:['get', '/payOrders/get'], 
   // delOrder:['post', '/payOrders/delete'], 

   stages:['get', '/contractOrders/stages'], 


  orderList:['post', '/api/order/page'], 

  doSettlement:['post', '/api/order/doSettlement'], 

  sendVerifyCode:['post', '/api/user/sendVerifyCode/SETTLEMENT'], 

  earlyRepayment:['post', '/api/order/earlyRepayment'], 


  tqhkCode:['post', '/api/user/sendVerifyCode/EARLY_REPAYMENT'], 

  payTest:['post', '/api/order/payTest'], 

  stageInfo:['post', '/api/order/stageInfo'], 

  
  listWithPaymentRecord:['post', '/api/order/listWithPaymentRecord'], 

  paymentRecordRead:['post', '/api/order/paymentRecordRead'], 


  paymentRecord:['post', '/api/order/paymentRecord'], 



  


  summaryAmount:['post', '/api/order/summaryAmount'], 
  
  
})