import Vue from 'vue'
import VueRouter from 'vue-router'
import Full from '../components/full.vue'
import utils from '../assets/js/utils'


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);


const routes = [
    {
        path: '/',
        name: 'Full',
        component: Full,
        redirect: {
            name: 'contractOrder',
        },
        meta: {
            title: '首页',
        },
        children: [
            {
                path: 'contractOrder',
                name: 'contractOrder',
                meta: {
                    title: '签约订单',
                },
                component: () => import('../views/contractOrder/index.vue'),
            },
            {
                path: '/contractOrder/addContractOrder',
                name: '/contractOrder/addContractOrder',
                meta: {
                    title: '签约订单/创建订单',
                },
                component: () => import('../views/contractOrder/addContractOrder.vue')
            },
            {
                path: '/contractOrder/orderDetails',
                name: '/contractOrder/orderDetails',
                meta: {
                    title: '签约订单/订单详情',
                },
                component: () => import('../views/contractOrder/orderDetails.vue')
            },
            {
                path: 'paymentOrder',
                name: 'paymentOrder',
                meta: {
                    title: '支付订单',
                },
                component: () => import('../views/paymentOrder/index.vue')
            },
            {
                path: '/paymentOrder/orderDetails',
                name: '/paymentOrder/orderDetails',
                meta: {
                    title: '支付订单/订单详情',
                },
                component: () => import('../views/paymentOrder/orderDetails.vue')
            },
            {
                path: 'historyOrder',
                name: 'historyOrder',
                meta: {
                    title: '历史订单',
                },
                component: () => import('../views/historyOrder/index.vue')
            },
            {
                path: '/historyOrder/orderDetails',
                name: '/historyOrder/orderDetails',
                meta: {
                    title: '历史订单/订单详情',
                },
                component: () => import('../views/historyOrder/orderDetails.vue')
            },
            {
                path: 'contractBackups',
                name: 'contractBackups',
                meta: {
                    title: '合同备案',
                },
                component: () => import('../views/contractBackups/index.vue')
            },
            {
                path: '/contractBackups/orderDetails',
                name: '/contractBackups/orderDetails',
                meta: {
                    title: '合同备案/订单详情',
                },
                component: () => import('../views/contractBackups/orderDetails.vue')
            },
            {
                path: 'onlineProtocol',
                name: 'onlineProtocol',
                meta: {
                    title: '线上协议',
                },
                component: () => import('../views/onlineProtocol/index.vue')
            },
            {
                path: 'changePassword',
                name: 'changePassword',
                meta: {
                    title: '修改手机号',
                },
                component: () => import('../views/LoginPage/changePassword.vue')
            },
            {
                path: 'businessConfigure',
                name: 'businessConfigure',
                meta: {
                    title: '商户管理',
                },
                component: () => import('../views/userConfigure/businessConfigure.vue')
            },
            {
                path: '/userConfigure/addBusinessPage',
                name: '/userConfigure/addBusinessPage',
                meta: {
                    title: '商户管理/创建商户',
                },
                component: () => import('../views/userConfigure/addBusinessPage.vue')
            },
            {
                path: 'accountConfigure',
                name: 'accountConfigure',
                meta: {
                    title: '账号管理',
                },
                component: () => import('../views/userConfigure/accountConfigure.vue')
            },
            {
                path: '/userConfigure/addAccount',
                name: '/userConfigure/addAccount',
                meta: {
                    title: '商户管理/创建账号',
                },
                component: () => import('../views/userConfigure/addAccount.vue')
            },
            {
                path: 'messagePage',
                name: 'messagePage',
                meta: {
                    title: '消息列表',
                },
                component: () => import('../views/messagePage/index.vue')
            },
            {
                path: 'messageDetail',
                name: 'messageDetail',
                meta: {
                    title: '扣款详情',
                },
                component: () => import('../views/messagePage/messageDetail.vue')
            },
            {
                path: 'platformList',
                name: 'platformList',
                meta: {
                    title: '支付平台列表',
                },
                component: () => import('../views/userConfigure/platformList.vue')
            },

            {
                path: 'tenadntOrderList',
                name: 'tenadntOrderList',
                meta: {
                    title: '租户订单列表',
                },
                component: () => import('../views/userConfigure/tenadntOrderList.vue')
            },
        ]
    },
    
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '后台管理系统登录'
        },
        component: () => import('../views/LoginPage')
    },
   
]

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name == "login") {
       
        let Gettoken = utils.storage.get("X-Access-Token")

        if (Gettoken) {
            next('/');
        }else{
            next();
        }
    } else {
        let Gettoken = utils.storage.get("X-Access-Token")

        if (Gettoken) {
            next();
        } else {
            utils.storage.remove("X-Access-Token");
            utils.storage.remove("shInfo");
            utils.storage.remove("mobileNumber");
            utils.storage.remove("userInfo");
            utils.storage.remove("isAdmin");
            utils.storage.remove("admJoinObj");
            next('/login');
        }
    }
})

router.afterEach(route => {
    window.scrollTo(0, 0);
})

export default router