<template>
    <div>
        <div class="shell" @mouseenter="enterMenu" @click.stop="enterMenu"  :class="showMenu ? 'showShell' : 'shell'">
            <ul class="nav">
                <li  id="logo">
                    <div>
                        <div class="logoicon">
                            <div class="imageBox">
                             
                            </div>
                        </div>
                        <div class="text">代扣信息管理系统</div>
                        
                    </div>
                </li>
                <li v-for="(item,index) in menuList" :class="menuIndex==index ? 'active' : ''" v-show="isAdmin">
                    <div class="item" @click.stop="goMenuItem(item,index)">
                        <div class="icon">
                            <i :class="item.icon"></i>
                        </div>
                        <div class="text">{{ item.name }}</div>
                    </div>
                </li>
                <li v-for="(item,index) in menuList" :class="menuIndex==index ? 'active' : ''" v-show="!isAdmin">
                    <div class="item" @click.stop="goMenuItem(item,index)" v-if="item.component!='businessConfigure'">
                        <div class="icon">
                            <i :class="item.icon"></i>
                        </div>
                        <div class="text">{{ item.name }}</div>
                    </div>
                </li>
            </ul>
            
        </div>
    </div>
</template>

<script>

export default {
   
    data(){
        return{
            showName:false,
            showMenu:false,
            menuIndex:'',
            menuList: [
                {
                    name: "签约订单",
                    component: "contractOrder",
                    icon:'el-icon-s-order'
                },
                {
                    name: "支付订单",
                    component: "paymentOrder",
                    icon:"el-icon-s-goods"
                },
                {
                    name: "历史订单",
                    component: "historyOrder",
                    icon:'el-icon-date'
                },
                {
                    name: "消息列表",
                    component: "messagePage",
                    icon:'el-icon-message'
                },
                {
                    name: "合同备案",
                    component: "contractBackups",
                    icon:'el-icon-document-copy'
                },
                {
                    name: "线上协议(暂定)",
                    component: "onlineProtocol",
                    icon:'el-icon-link'
                },
                {
                    name: "商户管理",
                    component: "businessConfigure",
                    icon:'el-icon-s-custom',
                    
                }
            ],

            isAdmin:this.$utils.storage.get("isAdmin")
        }
    },
    created(){
       
    },
    mounted(){
        document.body.addEventListener('click',this.leaveMenu)
        this.menuList.forEach((item,index)=>{
            if (this.$route.name==item.component) {
                this.goMenuItem(item,index)
            }
        })

    },

    methods:{
        goMenuItem(item,index){
            this.menuIndex=index
            if (item.chaildren && item.chaildren.length>0) {
                this.$router.push({
                    name: item.chaildren[0].component
                });
            }else{
                this.$router.push({
                    name: item.component
                });
            }
            // // this.showMenu=false
        },
        enterMenu(){
            this.showMenu=true
            setTimeout(()=>{
                this.showName=true
            },400)
           
            this.$emit('showMenu',this.showMenu)
        },
        leaveMenu(){
            this.showMenu=false
            this.showName=false
            this.$emit('showMenu',this.showMenu)
        },
    }
};
</script>

<style lang="scss" scoped>
.shell {
    position: fixed;
    top: 0;
    width: 50px;
    height: 100%;
    // background: #67abf1;
    background: #2E354D;
    z-index: 9999;
    transition: width 0.5s;
    padding-left: 10px;
    overflow: hidden;
    .name{
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        position: absolute;
        bottom: 50px;
    }
    ul {
        position: relative;
        li {
            position: relative;
            padding: 10px 5px;
            div {
                position: relative;
                display: flex;
                white-space: nowrap;
                cursor: pointer;
            }

            .text {
                position: relative;
                height: 36px;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #fff;
                padding-left: 10px;
                // text-transform: uppercase;
                // letter-spacing: 2px;
                transition: 0.5s;
                font-weight: 600;
            }
        }
        #logo {
            margin: 40px 0 60px 0;
            position: relative;
            padding: 5px;

            .imageBox {
                position: relative;
                width: 34px;
                height: 36px;
                overflow: hidden;
                background: url("../../assets//image/logo.png") no-repeat;
                background-size: 100%;
                
                background-position: center;
            }
        }
        .active {
            background: #fff;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            color: #ffa117;
            .text,
            .icon i {
                color: #ffa117;
            }

            div .icon::before {
                content: "";
                position: absolute;
                inset: 5px;
                width: 30px;
                background: #fff;
                border-radius: 50%;
                transition: 0.5s;
                border: 3px solid #ffa117;
                box-sizing: border-box;
            }
        }

        .active::before {
            content: "";
            position: absolute;
            top: -29px;
            right: 0;
            width: 30px;
            height: 30px;
            border-bottom-right-radius: 25px;
            box-shadow: 5px 5px 0 5px #fff;
            background: transparent;
        }

        .active::after {
            content: "";
            position: absolute;
            bottom: -29px;
            right: 0;
            width: 30px;
            height: 30px;
            border-top-right-radius: 25px;
            box-shadow: 5px -5px 0 5px #fff;
            background: transparent;
        }

        .icon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 30px;
            padding-left: 5px;
            height: 38px;
            color: #333;
            transition: 0.5s;
            color: rgb(255, 255, 255);
        }

        .icon i {
            font-size: 18px;
            z-index: 999;
        }
    }
}

.showShell {
    width: 200px;
}
</style>
