<template>
    <div>
        <div class="Header" v-if="showHeader">
        
            <div class="menuBox">
                <my-menu></my-menu>

                <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
                    <el-breadcrumb-item v-for="(m, mi) in breadcrumbList" :key="mi" :to="{ path: m.path }">{{
                        m.meta.title
                    }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            
            <user></user>
        </div>
        <div v-else class="wapHeader">
            <img src="../../assets/image/menu.png" alt="" @click="showMenu=!showMenu">
            <div class="menuList" v-if="!showHeader && showMenu" :class="showMenu ? 'menuListShow' : ''">
                <div v-for="(item,index) in menuList" :key="index" class="menuItem" @click="goMenuItem(item)">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import user from '../Header/user.vue';
import MyMenu from '../Header/menu.vue'
export default {
    name: "Header",
    props:{
        menuList:Array
    },
    data() {
        return {
            breadcrumbList: [],
            showHeader:true,
            showMenu:false
        };
    },
    mounted() {
        
        if (this.isMobile()) {
            this.showHeader=false
        } else {
            this.showHeader=true
        }
        this.breadcrumbList = this.$route.matched;
        let list=[]
        this.$route.matched.forEach((item,index)=>{
                if (item.name.indexOf('/')!=-1) {
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[0]
                        },
                        path:'/'+item.name.split('/')[1]
                    })
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[1]
                        }
                    })
                }else{
                    list.push(item)
                }
            })
            this.breadcrumbList =list
    },
    methods: {
        isMobile() {
            // 判断是否为移动设备
            return (
                typeof window.orientation !== "undefined" || // 判断是否存在window.orientation属性，此属性在移动设备上一般存在
                navigator.userAgent.indexOf('IEMobile') !== -1 || // 判断是否为Windows Phone
                navigator.userAgent.indexOf('iPhone') !== -1 || // 判断是否为iPhone
                navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1 || // 判断是否为Android手机
                navigator.userAgent.indexOf('BlackBerry') !== -1 || // 判断是否为BlackBerry
                navigator.userAgent.indexOf('Opera Mini') !== -1 // 判断是否为Opera Mini浏览器
            );
        },

        goMenuItem(item){
            this.$router.push({
                name: item.component
            });
            this.showMenu=false
        },
    },
    watch: {
        $route(to, from) {
            // this.breadcrumbList = to.matched;
            let list=[]
            to.matched.forEach((item,index)=>{
                if (item.name.indexOf('/')!=-1) {
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[0]
                        },
                        path:'/'+item.name.split('/')[1]
                    })
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[1]
                        }
                    })
                }else{
                    list.push(item)
                }
            })
            this.breadcrumbList =list
           
        }
    },
    components: {
        user,
        MyMenu
    },
};
</script>

<style lang="scss" scoped>
.Header {
    height: 60px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 20px 0 28px;
    box-sizing: border-box;
    position: fixed;

    right: 0;
    left: 200px;
    z-index: 99;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    .menuBox {
        flex: 1;
    }
}
@media (max-width: 600px) {
    .breadcrumb{
        // position: absolute;
        // top: 63px;
        // left: -180px;
        display: none;
    }

    .wapHeader{
        img{
            width: 20px;
            height: 20px;
        }
        .menuList{
            background: #404e68;
            width: 100%;
            position: fixed;
            top: 60px;
            right: 0;
            text-align: center;
            z-index: 999;
            height: 0;
            transition: height 0.5s ease-in-out;
            .menuItem{
                font-size: 16px;
                color: #fff;
                line-height: 46px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
        }
        .menuListShow{
            height: auto;
        }

    }
    
}

</style>